import { SliceZone, SliceZoneProps } from '@prismicio/react'
import { components } from 'slices'
import styled from 'styled-components'

export const PrismicSliceZone: React.FC<SliceZoneProps> = (props) => (
  <Wrapper>
    <SliceZone {...props} components={components} />
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.ms};

  section:first-child {
    margin-top: 0;
  }
`
