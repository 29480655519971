import type { Content } from '@prismicio/client'
import { GetStaticPaths, GetStaticProps } from 'next'
import { createClient } from 'prismicio'
import { ReactElement } from 'react'

import { AutoSeo } from '@/components/_layout/AutoSeo'
import { MainLayout } from '@/components/_layout/MainLayout'
import { PrismicSliceZone } from '@/components/_prismic/PrismicSliceZone'
import { PrismicTopPageHeading } from '@/components/_prismic/PrismicTopPageHeading'

import { INextPageWithLayout } from './_app'

interface IProps {
  pageDocument: Content.PageDocument
}

const PrismicPage: INextPageWithLayout<IProps> = ({ pageDocument }) => (
  <>
    <AutoSeo prismicDocument={pageDocument} />

    <PrismicTopPageHeading prismicDocument={pageDocument} />
    <PrismicSliceZone slices={pageDocument.data.slices} />
  </>
)

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps = async ({ params, previewData }) => {
  const client = createClient({ previewData })

  let pageDocument: Content.PageDocument<string>
  try {
    pageDocument = await client.getByUID('page', params.prismicUid.toString())
  } catch {
    return {
      notFound: true,
      revalidate: 60, // 1mn
    }
  }

  if (!pageDocument) {
    return {
      notFound: true,
      revalidate: 60, // 1mn
    }
  }

  return {
    props: {
      pageDocument,
    },
    revalidate: 3600, // 1h
  }
}

PrismicPage.getLayout = (page: ReactElement) => (
  <MainLayout
    disableAutoSeo
    disableAutoBreadcrumb
    hideHeaderNav={page.props.pageDocument?.data.headerLight ?? false}
  >
    {page}
  </MainLayout>
)

// eslint-disable-next-line import/no-default-export
export default PrismicPage
