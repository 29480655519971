import * as prismic from '@prismicio/client'
import styled, { css } from 'styled-components'

import { PrismicRichText } from '@/components/_prismic/PrismicRichText'
import { computeMarginTop, IStyleMarginTop } from '@/components/_prismic/style'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IPrismicSliceProps {
  slice: {
    slice_type: string
    variation: string
    primary: { styleMarginTop?: IStyleMarginTop; domId?: string }
  }
  fullWidth?: boolean
  header?: React.ReactNode
  className?: string
}

export const PrismicSlice: React.FC<React.PropsWithChildren<IPrismicSliceProps>> = ({
  children,
  slice,
  fullWidth = false,
  header,
  className,
}) => (
  <PrismicSliceWrapper
    data-slice-type={slice.slice_type}
    data-slice-variation={slice.variation}
    $fullWidth={fullWidth}
    $marginTop={slice.primary.styleMarginTop}
    id={slice.primary.domId}
    className={className}
  >
    {header}
    {children}
  </PrismicSliceWrapper>
)

export const PrismicSliceWrapper = styled.section<{
  $fullWidth: boolean
  $marginTop?: IStyleMarginTop
}>`
  ${({ $marginTop }) => css`
    margin: ${computeMarginTop($marginTop, true)} auto 0;
  `}

  padding: 0 20px;
  width: 100%;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    ${({ $marginTop }) => css`
      margin: ${computeMarginTop($marginTop, false)} auto 0;
    `}

    ${({ $fullWidth }) =>
      !$fullWidth &&
      css`
        max-width: 1440px;
      `}
  }
`

interface ISliceHeaderProps {
  topic?: string
  title?: string
  text?: prismic.RichTextField
}

export const SliceHeader: React.FC<ISliceHeaderProps> = ({ topic, title, text }) => {
  if (!topic && !title && (!text || !prismic.isFilled.richText(text))) {
    return null
  }

  return (
    <SliceHeaderWrapper>
      {topic && (
        <>
          <Topic>{topic}</Topic>
          <HorizontalRule />
        </>
      )}

      {title && <Title>{title}</Title>}

      {prismic.isFilled.richText(text) && (
        <CenteredWrapper>
          <PrismicRichText field={text} />
        </CenteredWrapper>
      )}
    </SliceHeaderWrapper>
  )
}

const SliceHeaderWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.s};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-bottom: ${({ theme }) => theme.spacing.l};
  }
`
export const Topic = styled.div`
  ${({ theme }) => theme.typo.overline}

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    color: ${({ theme }) => theme.color.greyDarker};
  }
`
export const HorizontalRule = styled.hr`
  background-color: ${({ theme }) => theme.color.black};
  border: none;
  height: 1px;
  margin: ${({ theme }) => `${theme.spacing.s} auto ${theme.spacing.ms}`};
  width: 160px;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    width: 240px;
  }
`
const Title = styled.h2`
  ${({ theme }) => theme.typo.h2}

  margin-bottom: ${({ theme }) => theme.spacing.m};
  text-align: center;
`
const CenteredWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.m};
  text-align: center;
`
