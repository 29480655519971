// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from 'next/dynamic'

export const components = {
  blog_post_list_carousel: dynamic(() => import('./BlogPostListCarousel'), {
    ssr: false,
  }),
  breadcrumb: dynamic(() => import('./Breadcrumb'), { ssr: false }),
  call_to_action: dynamic(() => import('./CallToAction'), { ssr: false }),
  card_list_carousel: dynamic(() => import('./CardListCarousel'), { ssr: false }),
  content_full_width: dynamic(() => import('./ContentFullWidth'), { ssr: false }),
  content_side_image: dynamic(() => import('./ContentSideImage'), { ssr: false }),
  image_full_width: dynamic(() => import('./ImageFullWidth'), { ssr: false }),
  image_list_grid: dynamic(() => import('./ImageListGrid'), { ssr: false }),
  iubenda_embed: dynamic(() => import('./IubendaEmbed'), { ssr: false }),
  legacy: dynamic(() => import('./Legacy'), { ssr: false }),
  menu_entry_list: dynamic(() => import('./MenuEntryList'), { ssr: false }),
  mixed_content: dynamic(() => import('./MixedContent'), { ssr: false }),
  offer_promocode: dynamic(() => import('./OfferPromocode'), { ssr: false }),
  promo_banner: dynamic(() => import('./PromoBanner'), { ssr: false }),
  specific_hero: dynamic(() => import('./SpecificHero'), { ssr: false }),
  table_of_contents: dynamic(() => import('./TableOfContents'), { ssr: false }),
  testimonial_list_carousel: dynamic(() => import('./TestimonialListCarousel'), {
    ssr: false,
  }),
  video_list_carousel: dynamic(() => import('./VideoListCarousel'), { ssr: false }),
}
