import type { Content } from '@prismicio/client'
import { FormattedDate } from 'react-intl'
import { Topic } from 'slices_components/PrismicSlice'
import styled from 'styled-components'

import { Container } from '@/components/_layout/Container'
import { DEVICE_WIDTH } from '@/utils/constants'

import { Heading1, Heading2 } from './style'

interface IProps {
  prismicDocument: Content.PageDocument | Content.BlogpostDocument
  withPublicationDate?: boolean
}

export const PrismicTopPageHeading: React.FC<IProps> = ({
  prismicDocument,
  withPublicationDate = false,
}) => {
  const publicationDate =
    ('publicationDateOverride' in prismicDocument.data &&
      prismicDocument.data.publicationDateOverride) ||
    prismicDocument.first_publication_date
  const subTitle =
    'subTitle' in prismicDocument.data ? prismicDocument.data.subTitle : null

  return (
    <StyledContainer>
      {withPublicationDate && (
        <Topic>
          <FormattedDate
            value={publicationDate}
            year="numeric"
            month="long"
            day="numeric"
          />
        </Topic>
      )}

      {prismicDocument.data.title && (
        <Heading1 $center $withSubTitle={!!subTitle}>
          {prismicDocument.data.title}
        </Heading1>
      )}

      {subTitle && <Heading2 $center>{subTitle}</Heading2>}
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  margin-top: 40px;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-top: 80px;

    h1 {
      padding: 0 15%;
    }
  }
`
